import React from 'react';
import Generic from '../../../components/287634/success/generic';
import { navigateToCustomPage } from '../../../utils/navigate';

const OnboardingPin = ({ location }) => {
  return (
    <Generic
      h1={`¡Fiu! ¡Fiuuu!<br />Código de seguridad activado con éxito. `}
      h2="Código de seguridad"
      imgSrc="/theme/assets/images/status/pin-success.svg"
      stepperNum={1}
      callback={() => navigateToCustomPage(`/liveness${location.search}`)?.()}
      cta="Continuar"
    />
  );
};

export default OnboardingPin;
